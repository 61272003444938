// @flow
import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';

// components
import PageTitle from '../../../../components/PageTitle';

import { t } from 'i18next';
import axios from 'axios';
import MessageAlert from '../../../../components/MessageAlert';

// main component
const ResourceSettings = (): React$Element<React$FragmentType> => {

  const [image, setImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({error : false, message : ''});
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    serial_number : '',
    client_id : '',
    client_secret : '',
    subscription_key : '',
  });

  const handleSubmit = (event) => {
        
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if(form.checkValidity()){
            submitForm(formData);
    }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const submitForm = ( data ) => {
        setLoading(true);
        //send the report of the job
        axios.post('/payment-settings/', data).then(function(response){
            console.log('Resource Settings response', response.data);

            setValidated(false);
            setLoading(false);
        });
    }

    const fetchPaymentSettings = () => {
        axios.get('/payment-settings/').then(function(response){
            console.log('company response', response.data);
            const obj = response.data.data;
            let updatedValue = {"serial_number" : obj.serial_number,
                                "client_id" : obj.client_id,
                                "client_secret" : obj.client_secret,
                                "subscription_key" : obj.subscription_key
                                };
            setFormData(formData => ({
                ...formData,
                ...updatedValue
            }));
            setLoading(false);

        }).catch(function(error){
            setError(error = ({
                ...error,
                ...{error : true, message : t('Resource Settings not found.')}
            }))
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchPaymentSettings();
    }, [])

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: t('Settings'), path: '', active: false},
                    {label: t('Resource Settings'), path: 'settings/company-profile', active: true},
                ]}
                title={'Resource Settings'}
            />
        
        <Row>
          <Col xs={12} sm={12} md={10}>
            <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Resource Settings')}</strong></Card.Title>


                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        
                        {error.error && <MessageAlert variant='danger' message={error.message} /> }
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="serial_number">
                                  <Form.Label>{t('What days are you business open? ')}</Form.Label>
                                  <Form.Group>
                                  <Form.Check 
                                        label={t('Monday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Tuesday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Wednesday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Thursday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Friday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Saturday')}
                                        inline
                                    />
                                    <Form.Check
                                        label={t('Sunday')}
                                        inline
                                    />
                                    </Form.Group>
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please add atleast 1 available day.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} md={6} lg={4}>
                                  <Form.Label>{t('What hours are you business open?')}</Form.Label>
                                    <table>
                                        <tr>
                                            <td><Form.Control label="Time" type="time" name="time" key="time" inline /> </td>
                                            <td className='px-2'> to </td>
                                            <td><Form.Control label="Time" type="time" name="time" key="time" inline /> </td>
                                            <td></td>
                                        </tr>
                                    </table>
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a client secret.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="form-group required" as={Col}>
                                  <Form.Label>{t('How many teams does your business have? ')}</Form.Label>
                                  <Form.Select
                                      required
                                      name="client_secret"
                                      onChange={handleInputChange}
                                      defaultValue={formData.client_secret}
                                      style={{maxWidth:'310px'}}
                                  >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a client secret.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="mt-5">
                              <Col className="text-end me-2">
                                  <Button disabled={loading && true} variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                      </Form>

                  </Card.Body>
            </Card>
          </Col>
        </Row>
        </>
    );
};

export default ResourceSettings;
