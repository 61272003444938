// @flow
import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Form, Button} from 'react-bootstrap';

// components
import PageTitle from '../../../../components/PageTitle';

import { t } from 'i18next';
import profileImg from '../../../../assets/images/users/avatar-2.jpg';
import PhoneWithCountry from '../../../../components/PhoneWithCountry';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import MessageAlert from '../../../../components/MessageAlert';
import {useSelector} from "react-redux";

// main component
const ProfileSettings = (): React$Element<React$FragmentType> => {

  const [image, setImage] = useState(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => ({
      user: state.Auth.user
  }));
  const [formData, setFormData] = useState({
    first_name : user.details.first_name,
    last_name : user.details.last_name,
    phone : user.details.phone,
    email : user.details.email,
    avatar_url : user.details.avatar_url,
  });


  const companyId = user.details.company_id;

  const [error, setError] = useState({
    message: '',
    errors: ''
   });

  const handleSubmit = (event) => {
        
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if(form.checkValidity()){
        if(image){        
            if (!(image instanceof File)) {
                // get file name from url
                const full = image.preview.split('/').pop();
                return Promise.resolve({data: {data: {links: {full}}}});
            }
            const data = new FormData();
            data.append('file', image, image.fileName);

            uploadFile(image)
            .then((response) => {
                const data = response.data.data;
                if (!data) {
                    throw new Error('Something went wrong');
                }
                console.log('upload success', data.links.full);
                setFormData({...formData, avatar_url  : data.links.full});

                submitForm(formData, data.links.full);
            
            })
            .catch(() => {
                setError({
                    message: t('Something went wrong with the image upload'),
                    errors: {image: t('Something went wrong with the image upload')}
                });
            });
        }else{
            submitForm(formData, null);
        }
    }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const submitForm = ( data, image) => {
        
        if(image){
            data['avatar_url'] = image;
        }
        //send the report of the job
        axios.put('/companies/'+companyId, data).then(function(response){
            console.log('company update response', response.data);

            setValidated(false);
        });
    }

    useEffect(() => {
        // fetchCompanyDetails();
    }, [])

    const onDrop = (files) => {
        const file = files[0];
        setImage(Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
    };

    const getDropzoneStyle = () => {
        return error.errors && error.errors.image ? {
            borderColor: '#fa5c7c'
        } : {};
    }

    const uploadFile = (file) => {
        if (!(file instanceof File)) {
            // get file name from url
            const full = file.preview.split('/').pop();
            return Promise.resolve({data: {data: {links: {full}}}});
        }
        const data = new FormData();
        data.append('file', file, file.fileName);
        return axios.post('images', data, {
            'accept': 'application/json',
            'Accept-Language': 'en-US,en;q=0.8',
            'Content-Type': 'multipart/form-data; boundary=' + data._boundary,
        });
    }

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: t('Settings'), path: '', active: false},
                    {label: t('Profile'), path: 'settings/profile', active: true},
                ]}
                title={'Company settings'}
            />
        
        <Row>
          <Col xs={12} sm={12} md={12} lg={7}>
            <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Profile')}</strong></Card.Title>
                      <Form noValidate validated={validated} onSubmit={handleSubmit}>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="name">
                                  <Form.Label>{t('First name')}</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder={t('Enter first name')}
                                      name="first_name"
                                      required
                                      defaultValue={formData.first_name}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a name.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="form-group required" as={Col} controlId="name">
                                  <Form.Label>{t('Last name')}</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder={t('Enter last name')}
                                      name="name"
                                      required
                                      defaultValue={formData.last_name}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a name.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="phone">
                                  <Form.Label>{t('Phone number')}</Form.Label>
                                  <Form.Control
                                      required
                                      type="text"
                                      placeholder="Enter phone number"
                                      name="phone"
                                      defaultValue={formData.phone}
                                      onChange={handleInputChange}
                                  />

                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company phone number.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className="my-3">
                              <Form.Group className="form-group required" as={Col} controlId="address">
                                  <Form.Label>{t('Email')}</Form.Label>
                                  <Form.Control
                                      required
                                      type="text"
                                      placeholder="Enter address"
                                      name="address"
                                      defaultValue={formData.email}
                                      onChange={handleInputChange}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      {t('Please provide a valid company address.')}
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Row>
                          <Row className='my-3'>
                                <Form.Group className="form-group required my-2" as={Col} controlId="upload">
                                  <Form.Label>{t('Avatar')}</Form.Label>
                                    <Dropzone required className='w-100' autocomplete="on" onDrop={onDrop} accept={'image/*'} multiple={false}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <>
                                                <div className="dropzone border-1" style={getDropzoneStyle()}>
                                                    <div className="dz-message" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {
                                                            image ?
                                                                <img src={image.preview}
                                                                        alt={"employee"}
                                                                        className="img-thumbnail"
                                                                        width={200}/>
                                                                : 
                                                                <>
                                                                <img alt='company' src={`${formData.avatar_url}`} width={200} />
                                                                <h4><strong>{t('Click to take photo')}</strong></h4>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </Dropzone> 

                                </Form.Group>
                            <Row>
                                <Col>
                                    <span className="text-danger">
                                        {error.message}
                                    </span>
                                </Col>
                            </Row>
                          </Row>
                          <Row className="mt-5">
                              <Col className="text-end">
                                  <Button disabled={loading && true} variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                      </Form>
                  </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
            <Col xs={12} sm={12} md={12} lg={7}>
                <Card>
                    <Card.Body>
                        <Card.Title><strong>{t('Change password')}</strong></Card.Title>
                        <Row className="my-3">
                            <Form.Group className="form-group required" as={Col} controlId="address">
                                <Form.Label>{t('Current password')}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter password"
                                    name="current_password"
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('Please provide a valid password.')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="my-3">
                            <Form.Group className="form-group required" as={Col} controlId="address">
                                <Form.Label>{t('New password')}</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter password"
                                    name="new_password"
                                    onChange={handleInputChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('Please provide a valid password.')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                          <Row className="mt-3">
                              <Col className="text-end">
                                  <Button disabled={loading && true} variant="primary" className="btn mb-2 " type={"submit"} >
                                      <i className="mdi mdi-content-save me-2"></i>Save
                                  </Button>
                              </Col>
                          </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    );
};

export default ProfileSettings;
