// @flow
import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Form, Button, Table, Overlay, OverlayTrigger, Tooltip} from 'react-bootstrap';

// components
import PageTitle from '../../../../components/PageTitle';

import { t } from 'i18next';
import axios from 'axios';
import MessageAlert from '../../../../components/MessageAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faSave } from '@fortawesome/pro-light-svg-icons';

// main component
const PriceSettings = (): React$Element<React$FragmentType> => {

    const [priceList, setPriceList] = useState([]);
    const [validated, setValidated] = useState(false);
    const [isSaving, setSaving]     = useState(false);

    const handleSavePrice = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        setValidated(true);

        if (form.checkValidity() === false) {
            return false;
        }else{
            setSaving(true);
            const min_area = document.getElementById('new_min_area').value;
            const max_area = document.getElementById('new_max_area').value;
            const price = document.getElementById('new_price').value;

            axios.post('/prices', {min_area : min_area, max_area : max_area, price : price} ).then((response) => {
                console.log('new price', response.data);

                fetchPrices();
                setValidated(false);
                event.target.reset();
                setSaving(false);
            }).catch((error) => {
                console.log(error);
                setSaving(false);
            })

            console.log(form.checkValidity());
        }

    }

    const handleRemovePrice = (e, priceId) => {

        console.log('hanndle remove price');

        axios.delete(`/prices/${priceId}`).then((response) => {
            console.log('delete price', response);
            document.getElementById('tr_'+priceId).classList.add('d-none');
        }).catch((error) => {
            console.log(error);
        })
    }

    const fetchPrices = () => {
        axios.get('/prices/').then((response) => {
            console.log('prices', response.data);
            setPriceList(response.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }

    const handlePriceChange = (e, priceId) => {
        console.log(priceId);
        const min_area = document.getElementById('min_area_'+priceId).value;
        const max_area = document.getElementById('max_area_'+priceId).value;
        const price = document.getElementById('price_'+priceId).value;

        if(min_area !== 0 && max_area !== 0 && price !== 0){
            axios.put(`/prices/${priceId}`, {min_area : min_area, max_area : max_area, price : price}).then((response) => {
                console.log('edit price '+priceId, response.data);
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    useEffect(() => {
        fetchPrices();
    }, [])

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    {label: t('Settings'), path: '', active: false},
                    {label: t('Price Settings'), path: 'settings/price-settings', active: true},
                ]}
                title={'Price Settings'}
            />
        
        <Row>
          <Col xs={12} sm={12} md={10}>
            <Card>
                  <Card.Body>
                    <Card.Title><strong>{t('Price Settings')}</strong></Card.Title>
                    <table className="mb-3">
                        <thead>
                            <tr>
                                <th colSpan={2}>Square meter range</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                        {priceList &&
                            priceList.map((obj, i) => {
                                return <tr key={i} id={`tr_${obj.id}`}>
                                            <td className="m-0 p-0">
                                            <Form.Control
                                                required
                                                onChange={(e) => handlePriceChange(e, obj.id) }
                                                defaultValue={obj.min_area}
                                                className="mt-2 form-control-sm"
                                                type="number"
                                                placeholder={t("Sqm")}
                                                name="min_area"
                                                id={`min_area_${obj.id}`}
                                                />
                                            </td>
                                            <td className="m-0 p-0 px-2">
                                            <Form.Control
                                                required
                                                onChange={(e) => handlePriceChange(e, obj.id) }
                                                defaultValue={obj.max_area}
                                                className="mt-2 form-control-sm"
                                                type="number"
                                                placeholder={t("Sqm")}
                                                name="max_area"
                                                id={`max_area_${obj.id}`}
                                            />
                                            </td>
                                            <td className="m-0 p-0">
                                            <Form.Control
                                                required
                                                onChange={(e) => handlePriceChange(e, obj.id) }
                                                defaultValue={obj.price}
                                                className="mt-2 form-control-sm"
                                                type="number"
                                                placeholder={t("Enter price")}
                                                name="sqmprice"
                                                id={`price_${obj.id}`}
                                                />
                                            </td>
                                            <td className='pt-2 ps-2'>
                                            <OverlayTrigger placement={"right"} overlay={
                                                <Tooltip id={`tooltip-${obj.id}`}>
                                                Remove price
                                                </Tooltip>
                                            }
                                            >
                                            <span className='cursor-pointer' 
                                                onClick={(e) => handleRemovePrice(e, obj.id) }>
                                                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" />
                                            </span>
                                            </OverlayTrigger>
                                            </td>
                                        </tr>
                            })
                        }
                        </tbody>
                    </table>
                    <hr/>
                    <p className='bold mb-0 font-18'>{t('Add new price')}</p>
                    <Form noValidate validated={validated} onSubmit={handleSavePrice}>
                        <table className='mt-0'>
                        <thead>
                            <tr>
                                <th colSpan={2}>Square meter range</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <Form.Control
                                    required
                                    className="form-control-sm"
                                    type="number"
                                    placeholder={t("Sqm")}
                                    id={`new_min_area`}
                                /></td>
                                <td>
                                    <Form.Control
                                    required
                                    className="form-control-sm"
                                    type="number"
                                    placeholder={t("Sqm")}
                                    id={`new_max_area`}
                                /></td>
                                <td>
                                    <Form.Control
                                    required
                                    className="form-control-sm"
                                    type="number"
                                    placeholder={t("Sqm")}
                                    id={`new_price`}
                                /></td>
                                <td className='ps-2'>
                                    <Button type='submit' className='btn btn-sm font-12'><FontAwesomeIcon icon={faSave} className='me-1'/>{isSaving ? t('Saving...') : t('Save')}</Button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Form>
                  </Card.Body>
            </Card>
          </Col>
        </Row>
        </>
    );
};

export default PriceSettings;
